import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import Wrapper from '../../styles/utilities/Wrapper';
import {
  colors,
  misc,
  breakpoints,
  font,
} from '../../styles/utilities/settings';
import dateFormatter from '../../js/dateFormatter';
import NewsLetter from '../forms/NewsLetter';
import { button } from '../../styles/utilities/elements';
import BackgroundImage, { SBackgroundImage } from '../helpers/BackgroundImage';

const NewEventsArray = (events) => {
  const updatedEvents = [];

  const date = new Date();
  const currentYear = date.getFullYear();

  events.forEach(({ node }) => {
    if (new Date(node.date) >= new Date()) {
      updatedEvents.push({
        node: {
          _id: node._id,
          title: node.title,
          locationName: node.locationName,
          cityStateZip: node.cityStateZip,
          street: node.street,
          description: node.description,
          _rawMainImage: node._rawMainImage,
          time: node.time,
          year: node.year === currentYear ? node.year : null,
          month: node.month,
          day: node.day,
          date: node.date,
          dateTime: new Date(node.date),
          link: node.link,
        },
      });
    }

    if (node.recurringDates) {
      node.recurringDates.forEach((reDate, index) => {
        if (new Date(dateFormatter(reDate, 1)) >= new Date()) {
          updatedEvents.push({
            node: {
              _id: node._id + index,
              title: node.title,
              locationName: node.locationName,
              _rawMainImage: node._rawMainImage,
              cityStateZip: node.cityStateZip,
              street: node.street,
              description: node.description,
              time: node.time,
              year:
                parseFloat(dateFormatter(reDate, 1, 'year')) !== currentYear
                  ? dateFormatter(reDate, 1, 'year')
                  : null,
              month: dateFormatter(reDate, 1, 'month'),
              day: parseFloat(dateFormatter(reDate, 1, 'day')) + 1,
              date: dateFormatter(reDate, 1),
              dateTime: new Date(dateFormatter(reDate, 1)),
              link: node.link,
            },
          });
        }
      });
    }
  });

  const sortedEvents = updatedEvents.sort(
    (a, b) => a.node.dateTime - b.node.dateTime
  );

  return updatedEvents.length !== 0 ? sortedEvents : events;
};

const EventsList = () => (
  <div>
    <StaticQuery
      query={EVENTSLIST_QUERY}
      render={({
        sanityEventMessage: { message },
        allSanityEvent: { edges },
      }) => (
        <Wrapper wide>
          <SEventsList>
            {NewEventsArray(edges).length !== 0 ? (
              NewEventsArray(edges).map(
                ({
                  node: {
                    _id,
                    _rawMainImage,
                    title,
                    month,
                    day,
                    locationName,
                    year,
                    description,
                    time,
                    cityStateZip,
                    street,
                    link,
                  },
                }) => (
                  <Event key={_id}>
                    <BackgroundImage src={_rawMainImage} />
                    <div className="inner">
                      <div className="date">
                        <span>
                          <strong>{day}</strong>
                        </span>
                        <span>{month}</span>
                        {year && (
                          <span>
                            <span>, </span>
                            {year}
                          </span>
                        )}
                      </div>
                      <div className="heading">
                        <h3>{title}</h3>
                        <div className="divider" />
                        <p>{time && time}</p>
                        {street && (
                          <a
                            className="location"
                            href={`https://www.google.com/maps/dir//${street} ${cityStateZip}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {locationName || `${street} ${cityStateZip}`}
                            {locationName && (
                              <sub>
                                {street}
                                <br />
                                {cityStateZip}
                              </sub>
                            )}
                          </a>
                        )}
                      </div>
                      <div className="description">
                        <p>{description}</p>
                        <a
                          className="link"
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </Event>
                )
              )
            ) : (
              <>
                <BlockContent blocks={message} />

                <NewsLetter>
                  <span>Subscribe to our newsletter:</span>
                </NewsLetter>
              </>
            )}
          </SEventsList>
        </Wrapper>
      )}
    />
  </div>
);

export default EventsList;

const EVENTSLIST_QUERY = graphql`
  {
    sanityEventMessage {
      message: _rawMessage(resolveReferences: { maxDepth: 10 })
    }

    allSanityEvent(sort: { fields: date, order: ASC }) {
      edges {
        node {
          _id
          title
          date
          locationName
          recurringDates
          _rawMainImage
          month: date(formatString: "MMM")
          day: date(formatString: "D")
          year: date(formatString: "YYYY")
          time
          cityStateZip
          street
          link
        }
      }
    }
  }
`;

const Event = styled.div`
  @media screen and (min-width: ${breakpoints.ipadPort + 1}px) {
    display: flex;
    flex-direction: row-reverse;
    height: 550px;
  }

  ${SBackgroundImage} {
    @media screen and (min-width: ${breakpoints.ipadPort + 1}px) {
      width: 50%;
    }

    @media screen and (max-width: ${breakpoints.ipadPort}px) {
      height: 0;
      padding-bottom: 100%;
      width: 100%;
    }
  }

  .inner {
    background-color: ${colors.yellow};
    padding: 50px 30px 30px 130px;
    position: relative;

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding: 82px 20px 30px 20px;
    }

    @media screen and (min-width: ${breakpoints.ipadPort + 1}px) {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .date {
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 45px 15px 10px 40px;
      font-family: ${font.primary};

      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        width: 103px;
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        width: 100%;
        padding: 20px 20px 10px;
      }

      span {
        display: block;
        font-size: 16px;
        line-height: 1;
        text-align: right;
        text-transform: uppercase;

        @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
          display: inline-block;
          margin-right: 4px;
        }

        > span {
          position: relative;
          left: -2px;
          @media screen and (min-width: ${breakpoints.ipadPort}px) {
            display: none;
          }
        }

        &:nth-child(1) {
          font-size: 20px;

          @media screen and (min-width: ${breakpoints.ipadPort}px) {
            font-size: 30px;
          }
        }
      }
    }

    h3 {
      margin-bottom: 10px;
      letter-spacing: 0.5px;
      line-height: 1.3;

      @media screen and (min-width: ${breakpoints.ipadPort + 1}px) {
        font-size: 40px;
      }
    }

    p,
    a {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
      color: ${colors.black};
      line-height: 1.5;
      transition-duration: ${misc.animSpeed};
    }

    .description {
      p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
        color: ${colors.black};
        line-height: 1.5;
        transition-duration: ${misc.animSpeed};
      }
    }

    .location {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .link {
      ${button};
      background-color: ${colors.black};
      margin: 30px 0 0;

      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        margin: 30px 0 20px;
      }

      &:hover,
      &:focus {
        background-color: ${colors.white};
        color: ${colors.black};
      }
    }
  }

  sub {
    display: block;
  }

  .divider {
    height: 3px;
    width: 100px;
    background-color: ${colors.lightYellow};
    margin: 20px 0;
  }
`;

const SEventsList = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${Event} {
    padding: 0 0 30px 0;

    @media screen and (min-width: ${breakpoints.mobile}px) {
      width: 100%;
    }
  }
`;
