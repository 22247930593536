import React from 'react';
import ImageLoader from '../helpers/ImageLoader';
import { breakpoints } from '../../styles/utilities/settings';
import Wrapper from '../../styles/utilities/Wrapper';
import styled from 'styled-components';

export default function VideoImage({ content }) {
  return (
    <SVideoImage>
      <Wrapper>
        <a className="imageLink" href={content.link.url}>
          <ImageLoader src={content.image} />
        </a>
      </Wrapper>
    </SVideoImage>
  );
}

const SVideoImage = styled.div`
  margin: 50px 30px 30px;

  @media screen and (min-width: ${breakpoints.ipadMid}px) {
    margin: 100px 70px 40px 40px;

    img {
      position: relative;
      margin: auto;
    }

    a.imageLink {
      display: block;
      width: 600px;
      margin: auto;
      cursor: pointer;
    }

    picture {
      padding-bottom: 400px;
    }
  }
`;
