import React, { Component } from 'react';
import styled from 'styled-components';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import HeadingContent, {
  HeadingContainer,
} from '../reusableObjects/HeadingContent';
import Wrapper from '../../styles/utilities/Wrapper';
import PageLink from '../helpers/PageLink';
import imageUrlFor, { buildImageObj } from '../../js/imageUrlFor';
import { colors, breakpoints } from '../../styles/utilities/settings';

class IconFeatures extends Component {
  render() {
    const {
      content: { sectionHeading, features },
    } = this.props;

    return (
      <SIconFeatures>
        <Wrapper>
          <Wrapper narrow>
            <HeadingContent content={sectionHeading} />
          </Wrapper>
          <div className="features">
            {features.map(({ _key, copy, heading, icon, link }) => (
              <Feature key={_key}>
                <div className="icon">
                  <img
                    src={imageUrlFor(buildImageObj(icon))}
                    alt={`${heading} icon`}
                  />
                </div>
                <h4>{heading}</h4>
                <p>{copy}</p>
                {link && link.copy && <PageLink content={link} />}
              </Feature>
            ))}
          </div>
        </Wrapper>
      </SIconFeatures>
    );
  }
}

export default IconFeatures;

const SIconFeatures = styled.div`
  background-color: ${colors.blue};
  color: ${colors.lightBlue};
  padding: 120px 0 100px;

  > ${Wrapper} {
    max-width: ${breakpoints.pageWidth + 200}px;

    > ${Wrapper} {
      max-width: ${breakpoints.pageWidth - 70}px;
    }
  }

  ${HeadingContainer} {
    text-align: center;
    margin-bottom: 70px;
    max-width: none;

    h2 {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        font-size: 40px;
      }
    }

    p {
      font-size: 20px;
      line-height: 1.6;
      font-weight: 600;
    }
  }

  .features {
    @media (max-width: ${breakpoints.ipadLand -
      1}px) and (min-width: ${breakpoints.ipadPort}px) {
      flex-wrap: wrap;
    }
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: flex;
      justify-content: center;
    }

    > div {
      + div {
        @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
          margin-top: 80px;
        }
      }
    }
  }
`;

const Feature = styled.div`
  padding: 0 30px;
  text-align: center;

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    width: calc(50% - 70px);
  }

  @media screen and (min-width: ${breakpoints.ipadLand}px) {
    width: calc(33.3333% - 70px);
  }

  .icon {
    background-color: ${colors.lightBlue};
    display: flex;
    margin: 0 auto 20px;
    height: 128px;
    width: 128px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: relative;
    z-index: 2;

    img {
      display: block;
      height: 50px;
      width: 50px;
      margin: 0;
    }
  }

  h4 {
    font-size: 20px;
    line-height: 1.5;
    width: 220px;
    margin: 0 auto;
  }
`;
