import React from 'react';
import styled from 'styled-components';
import HeadingSection, { SHeadingSection } from './HeadingSection';
import ImageLoader from '../helpers/ImageLoader';
import Wrapper from '../../styles/utilities/Wrapper';
import { colors, breakpoints, misc } from '../../styles/utilities/settings';
import { button } from '../../styles/utilities/elements';

const ShopHighlight = ({ content, content: { products, hideButton } }) => (
  <SShopHighlight>
    {content.sectionHeading && content.sectionHeading.heading && (
      <HeadingSection narrowCopy content={content} />
    )}
    <Wrapper>
      <div className="products">
        {products.map(({ title, link, price, image }, index) => (
          <Product
            href={link}
            key={title + index}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="image">
              <ImageLoader src={image} width={500} />
            </div>
            <div>
              <h5>{title}</h5>
              <p>{price}</p>
            </div>
          </Product>
        ))}
      </div>
    </Wrapper>
    {!hideButton && (
      <div className="button">
        <a
          href="https://dougthepugstore.com/Foundation"
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to Shop
        </a>
      </div>
    )}
  </SShopHighlight>
);

export default ShopHighlight;

const Product = styled.a`
  text-align: center;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition-duration: ${misc.animSpeed};

  &:hover {
    h5 {
      color: ${colors.blue};
    }
  }

  .image {
    width: 90%;
    margin: 0 auto;
  }

  h5 {
    color: ${colors.orange};
    font-size: 16px;
    margin: 20px 0 5px;
  }

  p {
    color: ${colors.black};
    font-weight: 400;
  }
`;

const SShopHighlight = styled.div`
  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -50px 30px 0;
  }

  ${SHeadingSection} {
    p {
      @media screen and (max-width: ${breakpoints.mobile - 1}px) {
        max-width: 240px;
        margin: 0 auto;
      }
    }
  }

  ${Product} {
    width: 33.333%;
    text-decoration: none;
    padding: 0 50px 20px 0;

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      width: 50%;
    }

    @media screen and (max-width: ${breakpoints.mobile - 1}px) {
      width: 100%;
    }
  }

  .button {
    text-align: center;

    a {
      ${button};
    }
  }
`;
