import React from 'react';
import styled from 'styled-components';
import SvgSelector from '../SvgSelector';
import BackgroundImage, { SBackgroundImage } from '../helpers/BackgroundImage';
import { colors, breakpoints } from '../../styles/utilities/settings';

const Interupter = ({ content }) => (
  <SInterupter>
    <div className="icon">
      <SvgSelector name="paw" />
    </div>
    <BackgroundImage src={content.image} />
  </SInterupter>
);

export default Interupter;

const SInterupter = styled.div`
  position: relative;

  .icon {
    box-sizing: content-box;
    display: block;
    margin: 0 auto -90px;
    background-color: ${colors.yellow};
    border-radius: 100%;
    padding: 30px;
    height: 100px;
    width: 100px;
    position: relative;
    z-index: 2;
    border: 7px solid ${colors.white};
    display: flex;
    justify-content: center;

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding: 20px;
      height: 70px;
      width: 70px;
      margin: 0 auto -65px;
    }

    @media screen and (max-width: ${breakpoints.mobile - 1}px) {
      padding: 5px;
      height: 70px;
      width: 70px;
      margin: 0 auto -50px;
    }

    svg {
      width: 70px;
      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        width: 50px;
      }

      @media screen and (max-width: ${breakpoints.mobile - 1}px) {
        width: 40px;
      }
    }
  }

  ${SBackgroundImage} {
    padding-bottom: 20%;

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding-bottom: 40%;
    }

    @media screen and (max-width: ${breakpoints.mobile - 1}px) {
      padding-bottom: 60%;
    }
  }
`;
