import React, { useState } from 'react';

import styled from 'styled-components';
import ReactPlayer from 'react-player';
import FaPlay from '@meronex/icons/fa/FaPlay';
import Wrapper from '../../styles/utilities/Wrapper';
import BackgroundImage, { SBackgroundImage } from '../helpers/BackgroundImage';
import { colors } from '../../styles/utilities/settings';
import { above } from '../../styles/utilities/mediaQueries';

const VimeoEmbed = ({ content }) => {
  const [active, setActive] = useState(false);

  return (
    <VideoWrapper>
      <Wrapper>
        {!active ? (
          <>
            <BackgroundImage src={content.videoImage} />
            <a
              href={null}
              onClick={() => {
                setActive(true);
              }}
            >
              <FaPlay />
            </a>
          </>
        ) : (
          <SVimeoEmbed>
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${content.vimeoId}`}
              loop
              playing
              value={0}
              title="Doug the Pug Foundation"
              muted={false}
              controls
              height="100%"
              width="100%"
            />
          </SVimeoEmbed>
        )}
      </Wrapper>
    </VideoWrapper>
  );
};

export default VimeoEmbed;

const VideoWrapper = styled.div`
  ${Wrapper} {
    position: relative;
    margin-top: 100px;
  }

  ${SBackgroundImage} {
    text-align: center;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
  }

  a {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    height: 100%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.blackOverlay2};

    ${above.ipadPort`
    cursor: pointer;
    width: calc(100% - 60px);
  `}
  }

  svg {
    height: 50px;
    width: 50px;
    position: relative;
    z-index: 1;
    fill: ${colors.blue};

    ${above.ipadPort`
      height: 100px;
      width: 100px;
    `}
  }
`;

const SVimeoEmbed = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
