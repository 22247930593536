import React from 'react';
import styled from 'styled-components';
import { imageBG } from '../../styles/utilities/elements';
import ImageLoader from './ImageLoader';

const BackgroundImage = ({ alt, children, height, width, className, src }) => (
  <SBackgroundImage className={className}>
    <ImageLoader height={height} width={width} src={src} alt={alt} />
    {children}
  </SBackgroundImage>
);

export default BackgroundImage;

export const SBackgroundImage = styled.div`
  ${imageBG};
`;
