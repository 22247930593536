import React from 'react';
import ImageLoader from '../helpers/ImageLoader';
import { breakpoints } from '../../styles/utilities/settings';
import Wrapper from '../../styles/utilities/Wrapper';
import styled from 'styled-components';

export default function SmallCenteredLogo({ content }) {
  return (
    <SSmallCenteredLogo>
      <Wrapper>
        <a className="imageLink" href={content.link.url}>
          <ImageLoader src={content.image} />
        </a>
      </Wrapper>
    </SSmallCenteredLogo>
  );
}

const SSmallCenteredLogo = styled.div`
  margin: 0 10px 40px 0;

  @media screen and (min-width: ${breakpoints.ipadMid}px) {
    margin: -80px 70px 100px 40px;

    img {
      position: relative;
      margin: auto;
    }

    a.imageLink {
      display: block;
      width: 400px;
      margin: auto;
    }

    picture {
      padding-bottom: 400px;
    }
  }
`;
