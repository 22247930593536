import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Wrapper from '../../styles/utilities/Wrapper';
import { colors, breakpoints } from '../../styles/utilities/settings';
// import Donations, { SDonations } from './parts/Donations';
import { button } from '../../styles/utilities/elements';
import ImageSizer from '../helpers/ImageSizer';
import BackgroundImage, { SBackgroundImage } from '../helpers/BackgroundImage';

export const DonationContext = React.createContext();

const DonationBox = ({
  banner,
  content: {
    image,
    link,
    altBgColor,
    sponsorTag,
    sectionHeading: { copy, heading },
  },
}) => (
  <SDonationBox banner={banner}>
    <Wrapper>
      <div className="container">
        <BackgroundImage src={image} />

        {sponsorTag && (
          <div className="tag">
            <ImageSizer src={sponsorTag} alt={copy} height={300} width={300} />
          </div>
        )}
        <Message banner={banner} altColor={altBgColor}>
          <h4>{heading}</h4>
          <BlockContent blocks={copy} />
          {link && (
            <>
              {link.newTab ? (
                <a href={link.url} target="_blank" rel="noreferrer">
                  {link.copy}
                </a>
              ) : (
                <Link to={link.url} className="logo">
                  {link.copy}
                </Link>
              )}
            </>
          )}
        </Message>
      </div>
    </Wrapper>
  </SDonationBox>
);

export default DonationBox;

const Message = styled.div`
  background-color: ${({ banner, altColor }) =>
    banner ? 'transparent' : altColor ? colors.blue : colors.yellow};
  padding: 50px;

  @media screen and (max-width: ${breakpoints.mobile - 1}px) {
    padding: 50px 30px 30px;
  }

  h4 {
    color: ${({ banner }) => (banner ? colors.blue : colors.black)};
  }
`;

const SDonationBox = styled.div`
  h4 {
    font-size: 30px;
  }

  .tag {
    background: ${colors.white};
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    padding: 5px;
    width: 80px;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      width: 120px;
      padding: 10px;
      bottom: 0;
      right: auto;
      left: 20px;
      top: auto;
    }

    img {
      display: block;
      margin: 0;
    }
  }

  .container {
    position: relative;
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: flex;
      flex-direction: ${({ banner }) => (banner ? 'row-reverse' : 'row')};
    }

    ${SBackgroundImage},
    ${Message} {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        width: 50%;
        display: flex;
        flex-flow: column;
        justify-content: center;
      }
    }

    ${SBackgroundImage} {
      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        height: 300px;
      }
    }

    ${Message} {
      p {
        font-size: 14px;
      }
    }

    a {
      ${button};
      text-align: center;
      background-color: ${colors.black};
      margin-top: 20px;

      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        display: block;
        text-align: center;
        margin-top: 30px;
      }

      &:hover {
        background-color: ${colors.white};
        color: ${colors.black};
      }
    }
  }
`;
