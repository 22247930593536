import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import PageLink from '../helpers/PageLink';
import { breakpoints, colors } from '../../styles/utilities/settings';
import { button } from '../../styles/utilities/elements';
import { above, below } from '../../styles/utilities/mediaQueries';
import Wrapper from '../../styles/utilities/Wrapper';
import BackgroundImage from '../helpers/BackgroundImage';
import ImageLoader from '../helpers/ImageLoader';

// Single Feature Component
const SingleFeature = ({
  contained,
  flip,
  content: { image, heading, copy, link },
}) => (
  <SFeature flip={flip} contained={contained}>
    {!contained ? (
      <BackgroundImage
        className="image"
        src={image}
        maxWidth={breakpoints.pageWidth / 2}
        alt={heading}
      />
    ) : (
      <div className="image">
        <ImageLoader src={image} alt={heading} />
      </div>
    )}
    <div className="content">
      <h3>{heading}</h3>
      {copy && <BlockContent blocks={copy} />}
      {link && link.copy && (
        <PageLink
          style={{
            marginTop: '20px',
          }}
          content={link}
        />
      )}
    </div>
  </SFeature>
);

// Single Feature Styles
export const SFeature = styled.div`
  ${above.ipadPort`
    display: flex;
    align-items: ${({ contained }) => (contained ? 'center' : 'stretch')};
    flex-direction: ${({ flip }) => (flip ? 'row-reverse' : 'row')};
  `}

  &:nth-child(2n) {
    flex-direction: ${({ flip }) => (flip ? 'row' : 'row-reverse')};

    .image {
      ${above.ipadPort`
      margin-right: 0px;
      margin-left: 70px;
    `}
    }
  }

  .image {
    align-items: ${({ contained }) => (contained ? 'center' : 'stretch')};

    ${above.ipadPort`
      width: ${({ contained }) => (contained ? '60%' : '50%')};
      margin-right: 70px;
    `}

    ${below.ipadPort`
      margin: 0 auto 30px;;
      padding-bottom: ${({ contained }) => (contained ? '0' : '56%')};;
    `}

    ${below.mobile`
      max-width: 300px;
      margin: 0 auto;
      padding-bottom: ${({ contained }) => (contained ? '0' : '56%')};;
    `}
  }

  .content {
    padding: 70px 0;

    ${above.ipadPort`
      max-width: ${breakpoints.pageWidth / 2}px;
      width: ${({ contained }) => (contained ? '40%' : '50%')};
    `}

    ${below.ipadPort`
      padding: 0 30px 50px;
    `}

    > a {
      ${button};
    }

    h3 {
      font-size: 40px;
      color: ${colors.blue};
    }

    p {
      font-size: 14px;
      line-height: 1.6;
    }
  }
`;

//* **************** *///
//* **************** *///

// Feature List Component
const ImageFeatures = ({ content: { features, contained, flipImageSide } }) => (
  <SImageFeatures full={!contained}>
    <Wrapper full={!contained}>
      {features.map((feature) => (
        <SingleFeature
          key={feature._key}
          content={feature}
          flip={flipImageSide}
          contained={contained}
        />
      ))}
    </Wrapper>
  </SImageFeatures>
);

export default ImageFeatures;

const SImageFeatures = styled.div`
  ${Wrapper} {
    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      ${({ full }) => (full ? 'padding: 0;' : '')}
    }
  }
`;
