import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  misc,
  colors,
  font,
  breakpoints,
} from '../../styles/utilities/settings';
import { absoluteCenter } from '../../styles/utilities/elements';
import ImageSizer from '../helpers/ImageSizer';
import imageUrlFor, { buildImageObj } from '../../js/imageUrlFor';
import Loader from '../helpers/Loader';
import Delayed from '../../js/Delayed';

const ImageGallery = ({ content }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (
        e.target.id === 'outter' ||
        e.target.id === 'lightboxInner' ||
        e.key === 'Escape'
      ) {
        setModalOpen(false);
      }
    });

    document.onkeydown = (e) => {
      if (e.keyCode === 27) {
        setModalOpen(false);
      }
    };
  }, []);
  return (
    <SGallery>
      {content.gallery.map((image, index) => (
        <a
          key={`image${index}`}
          href={null}
          onClick={() => {
            setActiveIndex(index);
            setImageLoading(true);
            setModalOpen(true);
          }}
        >
          {image.asset.title && (
            <span className="title">{image.asset.title}</span>
          )}
          <ImageSizer src={image} height={600} width={600} />
        </a>
      ))}
      {modalOpen && (
        <SLightBox id="outter">
          {imageLoading && (
            <Delayed waitBeforeShow={100}>
              <Loader />
            </Delayed>
          )}
          <a
            href={null}
            onClick={() => {
              setModalOpen(false);
            }}
            className="close"
          >
            <span />
            <span />
          </a>
          {activeIndex - 1 !== -1 && (
            <a
              href={null}
              onClick={() => {
                setImageLoading(true);
                setActiveIndex(activeIndex - 1);
              }}
              className="control previous"
            >
              <span />
              <span />
            </a>
          )}
          {activeIndex !== content.gallery.length - 1 && (
            <a
              href={null}
              onClick={() => {
                setImageLoading(true);
                setActiveIndex(activeIndex + 1);
              }}
              className="control next"
            >
              <span />
              <span />
            </a>
          )}
          <div className="inner" id="lightboxInner">
            <div className={`image-container${imageLoading ? ' loading' : ''}`}>
              <img
                onLoad={() => {
                  setImageLoading(false);
                }}
                alt="..."
                src={imageUrlFor(buildImageObj(content.gallery[activeIndex]))
                  .quality(100)
                  .fit('crop')
                  .width(1500)
                  .auto('format')
                  .url()}
              />
              {content.gallery[activeIndex].asset.title && (
                <h3
                  style={{
                    textAlign: 'center',
                    marginTop: '20px',
                    color: colors.white,
                  }}
                >
                  {content.gallery[activeIndex].asset.title}
                </h3>
              )}
            </div>
          </div>
        </SLightBox>
      )}
    </SGallery>
  );
};

export default ImageGallery;

const SLightBox = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${colors.blackOverlay};
  top: 0;
  left: 0;
  z-index: 100;

  .control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 60px;
    z-index: 101;
    cursor: pointer;
    text-decoration: none;
    color: ${colors.white};

    span {
      display: block;
      height: 3px;
      width: 30px;
      background-color: ${colors.white};
      top: 17px;
      position: relative;

      + span {
        margin-top: 18px;
      }
    }
  }

  .previous {
    left: 5%;

    span {
      transform: rotate(-45deg);
      + span {
        transform: rotate(45deg);
      }
    }
  }

  .next {
    right: 5%;

    span {
      transform: rotate(45deg);

      + span {
        transform: rotate(-45deg);
      }
    }
  }

  .close {
    position: absolute;
    right: 5%;
    top: 5%;
    height: 30px;
    z-index: 101;
    cursor: pointer;
    text-decoration: none;
    color: ${colors.white};

    &:hover {
      color: ${colors.white};
    }

    &:before {
      content: 'Close';
      display: block;
      position: absolute;
      font-family: ${font.primary};
      text-transform: uppercase;
      right: calc(100% + 10px);
      top: calc(50% - 13px);
      transform: translateY(-50%);
    }

    span {
      display: block;
      height: 3px;
      width: 30px;
      background-color: ${colors.white};
      transform: rotate(45deg);

      + span {
        margin-top: -3px;
        transform: rotate(-45deg);
      }
    }
  }

  .inner {
    width: 100%;
    ${absoluteCenter};
  }

  .image-container {
    max-width: 1400px;
    max-height: 90vh;
    margin: 0 auto;

    &.loading {
      img {
        opacity: 0;
      }
    }

    img {
      max-width: 100%;
      max-height: 90vh;
      height: auto;
      width: auto;
      margin: 0 auto;
      opacity: 1;
      transition-duration: ${misc.animSpeed};
    }
  }
`;

export const SGallery = styled.div`
  display: flex;
  margin: 0 -1px ${misc.sectionMargin} 0;

  @media screen and (max-width: ${breakpoints.ipadPort}px) {
    flex-wrap: wrap;
  }

  img {
    margin-bottom: 0;
  }

  > a {
    padding: 0 1px 1px 0;
    width: 50%;
    cursor: pointer;
    position: relative;

    &:hover {
      .title {
        @media screen and (min-width: ${breakpoints.ipadLand + 1}px) {
          height: 100%;
        }
      }
    }

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      width: 25%;
    }

    > span {
      overflow: hidden;
      display: block;
      height: 162px;
      position: relative;

      &.title {
        position: absolute;
        z-index: 2;
        color: ${colors.white};
        font-weight: 600;
        height: auto;
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        font-size: 25px;
        line-height: 1;
        padding: 15px;
        align-items: flex-end;
        opacity: 0.8;
        pointer-events: none;
        background-color: ${colors.blue};

        @media screen and (max-width: ${breakpoints.ipadLand}px) {
          font-size: 18px;
          padding: 8px;
        }
      }

      img {
        height: 100%;
        width: 100%;
        ${absoluteCenter};
      }
    }
  }
`;
