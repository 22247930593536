import React from 'react';
import ImageLoader from './ImageLoader';

const ImageSizer = ({ src, height, width, alt }) => (
  <>
    {height ? (
      <ImageLoader
        src={src}
        width={width}
        height={height}
        alt={alt}
        quality={90}
      />
    ) : (
      <ImageLoader src={src} width={width} alt={alt} quality={100} />
    )}
  </>
);

export default ImageSizer;
