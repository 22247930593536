import React from 'react';
import styled from 'styled-components';
import { misc, colors, breakpoints } from '../../styles/utilities/settings';
import Wrapper from '../../styles/utilities/Wrapper';
import { whiteOutlineButton } from '../../styles/utilities/elements';
import HeadingContent, {
  HeadingContainer,
} from '../reusableObjects/HeadingContent';
import imageUrlFor, { buildImageObj } from '../../js/imageUrlFor';

const CallToActionBanner = ({
  content,
  content: { icon, backgroundColor },
}) => (
  <SCallToActionBanner
    icon={icon}
    style={{
      backgroundColor: backgroundColor ? backgroundColor.hex : colors.blue,
      color: colors.white,
      textAlign: 'center',
    }}
  >
  {/* comment */}
    <Wrapper narrow>
      {icon && (
        <div className="icon">
          <img src={imageUrlFor(buildImageObj(icon))} alt=".." />
        </div>
      )}
      <HeadingContent content={content} />
    </Wrapper>
  </SCallToActionBanner>
);

export default CallToActionBanner;

const SCallToActionBanner = styled.div`
  padding: ${({ icon }) =>
    icon ? '120px 0 80px' : `${misc.sectionMargin}px 0`};
  position: relative;

  > a {
    ${whiteOutlineButton};
  }

  ${HeadingContainer} {
    max-width: none;

    h2 {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        font-size: 40px;
      }
    }

    p {
      font-size: 14px;
      line-height: 1.8;
      font-weight: 600;
    }
  }

  .icon {
    background-color: ${colors.lightBlue};
    display: flex;
    margin: 0;
    height: 128px;
    width: 128px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: absolute;
    z-index: 2;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);

    img {
      display: block;
      height: 60px;
      width: 60px;
      margin: 0;
    }
  }
`;
