import React from 'react';
import { colors } from '../styles/utilities/settings';

const SvgSelector = ({ name, color, width }) => (
  <>
    {name === 'paw' && (
      <svg width={width || null} viewBox="0 0 110.000000 108.000000">
        <g
          transform="translate(0.000000,108.000000) scale(0.100000,-0.100000)"
          fill={color || colors.black}
          stroke="none"
        >
          <path d="M290 1052 c-76 -77 -55 -256 40 -336 89 -74 191 12 177 148 -17 165 -136 268 -217 188z" />
          <path d="M691 1063 c-86 -43 -141 -229 -91 -310 21 -36 66 -63 103 -63 68 0 132 88 143 197 9 83 -1 127 -37 163 -35 34 -67 38 -118 13z" />
          <path d="M47 808 c-64 -49 -60 -224 8 -313 70 -92 161 -83 201 20 45 119 -48 305 -154 305 -21 0 -46 -6 -55 -12z" />
          <path d="M919 798 c-21 -14 -45 -46 -64 -83 -25 -50 -30 -72 -30 -132 0 -68 2 -75 31 -104 23 -23 44 -33 78 -36 43 -5 48 -3 85 33 46 47 71 119 71 209 0 60 -14 94 -50 122 -25 20 -84 15 -121 -9z" />
          <path d="M455 586 c-58 -26 -208 -201 -240 -281 -45 -115 -5 -205 114 -259 128 -57 305 -57 430 0 151 68 176 175 79 326 -52 80 -163 197 -205 214 -41 18 -139 17 -178 0z" />
        </g>
      </svg>
    )}
    {name === 'row' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || '2029.155'}
        height="70.495"
        viewBox="0 0 2029.155 70.495"
      >
        <g id="Group_16" data-name="Group 16" opacity="0.143">
          <path
            id="Path_198"
            data-name="Path 198"
            d="M74.562,283.822s11.659.559,27.07-12.908,16.129-15.24,14.3-18.187c-1.807-2.919-6.249-1.67-11.249,1.665s-7.081,7.355-9.858,6.665-3.968-10.844.969-23.672c5-12.982,5.416-17.218,2.36-18.4-2.788-1.078-5.205.7-8.883,11.175-3.666,10.445-5.069,13.193-6.249,12.845-1.465-.433-1.249-10.137-1.112-16.386s-.274-13.29-4.316-13.29c-3.193,0-4.846,1.631-4.43,12.321s.553,16.8-.832,16.8c-1.431,0-4.236-9.168-5.764-15.137-.65-2.549-2.993-10.086-7.036-8.478-3.86,1.539-.77,11.386.063,14.886.165.69.336,1.374.519,2.064.7,2.634,2.218,10.747.7,11.659-1.739,1.043-5.553-3.609-8.746-8.33s-4.863-4.721-6.249-3.746-1.944,3.746,1.665,10.827A92.713,92.713,0,0,1,55.4,269.243C56.648,276.051,64.7,283.406,74.562,283.822Z"
            transform="translate(248.94 -213.33)"
            fill={color || colors.blue}
          />
          <g
            id="Group_3"
            data-name="Group 3"
            transform="translate(393.458 1.623)"
          >
            <path
              id="Path_199"
              data-name="Path 199"
              d="M86.2,256.552s42.093-1.545,9.282-33.969a13.236,13.236,0,0,0-18.558,0C44.11,255.007,86.2,256.552,86.2,256.552"
              transform="translate(-52.12 -189.302)"
              fill={color || colors.blue}
            />
            <g id="Group_2" data-name="Group 2" transform="translate(0)">
              <path
                id="Path_200"
                data-name="Path 200"
                d="M73.114,213.742c2.845.5,5.776,3.358,7.583,9.561,1.522,5.223.639,8.883-.678,11.266a6.136,6.136,0,0,1-8.136,2.68c-2.206-1.146-4.664-3.563-6.2-8.58C63.741,222.362,65.349,212.374,73.114,213.742Z"
                transform="translate(-49.658 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_201"
                data-name="Path 201"
                d="M64.186,218.688a5.073,5.073,0,0,1,5.867-2.018c2.737.929,6.357,3.33,8.164,9.538,1.528,5.217.639,8.877-.679,11.26a6.134,6.134,0,0,1-8.136,2.68c-2.206-1.146-4.664-3.563-6.2-8.58C61.261,225.262,62.658,221.094,64.186,218.688Z"
                transform="translate(-62.253 -200.493)"
                fill={color || colors.blue}
              />
              <path
                id="Path_202"
                data-name="Path 202"
                d="M81.392,214.192a4.619,4.619,0,0,0-2.566-.57c-2.417.125-6.916,1.482-9.191,9.3C66.7,232.95,70.645,236.53,74.322,237.4c4.949,1.169,9.122-2.429,10.542-9.755C86.278,220.333,84.881,216.211,81.392,214.192Z"
                transform="translate(-32.348 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_203"
                data-name="Path 203"
                d="M83.529,216.559s-7.56-2.047-11.095,8.814c-3.216,9.886.61,13.216,4.265,14.539,4.778,1.733,9.185-2.138,10.821-9.379C89.288,222.7,87.634,218.486,83.529,216.559Z"
                transform="translate(-20.061 -200.491)"
                fill={color || colors.blue}
              />
            </g>
          </g>
          <path
            id="Path_204"
            data-name="Path 204"
            d="M144.627,219.352h0a17.284,17.284,0,0,0-24.453,0l-5.718,5.713-5.713-5.713a17.284,17.284,0,0,0-24.453,0h0a17.29,17.29,0,0,0-.006,24.453l30.08,30.08.091-.091.1.091,30.075-30.08A17.284,17.284,0,0,0,144.627,219.352Z"
            transform="translate(410.979 -208.839)"
            fill={color || colors.blue}
          />
          <path
            id="Path_205"
            data-name="Path 205"
            d="M23.043,283.822s11.665.559,27.076-12.908,16.129-15.24,14.3-18.187c-1.8-2.919-6.243-1.67-11.243,1.665s-7.081,7.355-9.858,6.665-3.968-10.844.969-23.672c5-12.982,5.416-17.218,2.36-18.4-2.788-1.078-5.205.7-8.883,11.175-3.666,10.445-5.069,13.193-6.249,12.845-1.465-.433-1.249-10.137-1.112-16.386s-.279-13.29-4.316-13.29c-3.193,0-4.846,1.631-4.43,12.321s.553,16.8-.832,16.8c-1.431,0-4.236-9.168-5.764-15.137-.65-2.549-2.993-10.086-7.036-8.478-3.86,1.539-.77,11.386.063,14.886.165.69.336,1.374.519,2.064.7,2.634,2.218,10.747.7,11.659-1.739,1.043-5.553-3.609-8.752-8.33s-4.858-4.721-6.243-3.746-1.944,3.746,1.665,10.827a92.713,92.713,0,0,1,7.914,23.045C5.135,276.051,13.186,283.406,23.043,283.822Z"
            transform="translate(6.758 -213.33)"
            fill={color || colors.blue}
          />
          <g
            id="Group_5"
            data-name="Group 5"
            transform="translate(99.762 1.623)"
          >
            <path
              id="Path_206"
              data-name="Path 206"
              d="M34.685,256.552s42.088-1.545,9.276-33.969a13.229,13.229,0,0,0-18.552,0c-32.811,32.424,9.276,33.969,9.276,33.969"
              transform="translate(-0.607 -189.302)"
              fill={color || colors.blue}
            />
            <g id="Group_4" data-name="Group 4" transform="translate(0)">
              <path
                id="Path_207"
                data-name="Path 207"
                d="M21.6,213.742c2.845.5,5.775,3.358,7.583,9.561,1.522,5.223.639,8.883-.678,11.266a6.136,6.136,0,0,1-8.136,2.68c-2.206-1.146-4.664-3.563-6.209-8.58C12.23,222.362,13.838,212.374,21.6,213.742Z"
                transform="translate(1.853 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_208"
                data-name="Path 208"
                d="M12.673,218.688a5.073,5.073,0,0,1,5.867-2.018c2.737.929,6.357,3.33,8.164,9.538,1.522,5.217.639,8.877-.678,11.26a6.136,6.136,0,0,1-8.136,2.68c-2.206-1.146-4.664-3.563-6.2-8.58C9.748,225.262,11.145,221.094,12.673,218.688Z"
                transform="translate(-10.74 -200.493)"
                fill={color || colors.blue}
              />
              <path
                id="Path_209"
                data-name="Path 209"
                d="M29.881,214.192a4.619,4.619,0,0,0-2.566-.57c-2.423.125-6.916,1.482-9.2,9.3-2.925,10.029,1.015,13.609,4.692,14.476,4.949,1.169,9.122-2.429,10.542-9.755C34.767,220.333,33.37,216.211,29.881,214.192Z"
                transform="translate(19.163 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_210"
                data-name="Path 210"
                d="M32.016,216.559s-7.56-2.047-11.095,8.814c-3.216,9.886.61,13.216,4.265,14.539,4.778,1.733,9.185-2.138,10.821-9.379C37.775,222.7,36.121,218.486,32.016,216.559Z"
                transform="translate(31.452 -200.491)"
                fill={color || colors.blue}
              />
            </g>
          </g>
          <path
            id="Path_211"
            data-name="Path 211"
            d="M93.114,219.352h0a17.284,17.284,0,0,0-24.453,0l-5.718,5.713-5.713-5.713a17.291,17.291,0,0,0-24.459,0h0a17.3,17.3,0,0,0,0,24.453l30.081,30.08.091-.091.091.091,30.081-30.08A17.284,17.284,0,0,0,93.114,219.352Z"
            transform="translate(168.796 -208.839)"
            fill={color || colors.blue}
          />
          <path
            id="Path_212"
            data-name="Path 212"
            d="M126.075,283.822s11.659.559,27.07-12.908,16.129-15.24,14.3-18.187c-1.807-2.919-6.249-1.67-11.249,1.665s-7.081,7.355-9.858,6.665-3.968-10.844.975-23.672c4.994-12.982,5.411-17.218,2.36-18.4-2.794-1.078-5.211.7-8.888,11.175-3.666,10.445-5.069,13.193-6.249,12.845-1.465-.433-1.249-10.137-1.112-16.386s-.274-13.29-4.316-13.29c-3.193,0-4.846,1.631-4.43,12.321s.559,16.8-.832,16.8c-1.431,0-4.236-9.168-5.764-15.137-.65-2.549-2.993-10.086-7.036-8.478-3.86,1.539-.77,11.386.063,14.886.165.69.336,1.374.519,2.064.7,2.634,2.218,10.747.7,11.659-1.739,1.043-5.553-3.609-8.746-8.33s-4.863-4.721-6.249-3.746S95.39,239.117,99,246.2a92.715,92.715,0,0,1,7.913,23.045C108.161,276.051,116.217,283.406,126.075,283.822Z"
            transform="translate(491.123 -213.33)"
            fill={color || colors.blue}
          />
          <g
            id="Group_7"
            data-name="Group 7"
            transform="translate(687.153 1.623)"
          >
            <path
              id="Path_213"
              data-name="Path 213"
              d="M137.714,256.552s42.088-1.545,9.276-33.969a13.236,13.236,0,0,0-18.558,0c-32.806,32.424,9.282,33.969,9.282,33.969"
              transform="translate(-103.63 -189.302)"
              fill={color || colors.blue}
            />
            <g id="Group_6" data-name="Group 6">
              <path
                id="Path_214"
                data-name="Path 214"
                d="M124.627,213.742c2.845.5,5.775,3.358,7.583,9.561,1.522,5.223.639,8.883-.678,11.266a6.136,6.136,0,0,1-8.136,2.68c-2.206-1.146-4.664-3.563-6.2-8.58C115.254,222.362,116.862,212.374,124.627,213.742Z"
                transform="translate(-101.171 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_215"
                data-name="Path 215"
                d="M115.7,218.688a5.073,5.073,0,0,1,5.867-2.018c2.737.929,6.357,3.33,8.164,9.538,1.528,5.217.639,8.877-.678,11.26a6.135,6.135,0,0,1-8.136,2.68c-2.206-1.146-4.664-3.563-6.2-8.58C112.774,225.262,114.171,221.094,115.7,218.688Z"
                transform="translate(-113.766 -200.493)"
                fill={color || colors.blue}
              />
              <path
                id="Path_216"
                data-name="Path 216"
                d="M132.9,214.192a4.619,4.619,0,0,0-2.566-.57c-2.417.125-6.916,1.482-9.191,9.3-2.925,10.029,1.009,13.609,4.687,14.476,4.955,1.169,9.122-2.429,10.542-9.755C137.795,220.333,136.392,216.211,132.9,214.192Z"
                transform="translate(-83.859 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_217"
                data-name="Path 217"
                d="M135.042,216.559s-7.56-2.047-11.095,8.814c-3.216,9.886.61,13.216,4.265,14.539,4.778,1.733,9.185-2.138,10.821-9.379C140.8,222.7,139.147,218.486,135.042,216.559Z"
                transform="translate(-71.574 -200.491)"
                fill={color || colors.blue}
              />
            </g>
          </g>
          <path
            id="Path_218"
            data-name="Path 218"
            d="M196.145,219.352h0a17.291,17.291,0,0,0-24.459,0l-5.713,5.713-5.719-5.713a17.284,17.284,0,0,0-24.453,0h0a17.285,17.285,0,0,0,0,24.453l30.081,30.08.091-.091.091.091,30.08-30.08A17.3,17.3,0,0,0,196.145,219.352Z"
            transform="translate(653.162 -208.839)"
            fill={color || colors.blue}
          />
          <path
            id="Path_219"
            data-name="Path 219"
            d="M177.588,283.822s11.659.559,27.07-12.908,16.129-15.24,14.3-18.187c-1.807-2.919-6.249-1.67-11.249,1.665s-7.081,7.355-9.858,6.665-3.968-10.844.975-23.672c4.994-12.982,5.411-17.218,2.36-18.4-2.794-1.078-5.211.7-8.889,11.175-3.666,10.445-5.068,13.193-6.249,12.845-1.465-.433-1.249-10.137-1.112-16.386s-.274-13.29-4.31-13.29c-3.2,0-4.852,1.631-4.436,12.321s.559,16.8-.832,16.8c-1.431,0-4.236-9.168-5.758-15.137-.656-2.549-3-10.086-7.041-8.478-3.86,1.539-.77,11.386.063,14.886.165.69.342,1.374.519,2.064.7,2.634,2.218,10.747.7,11.659-1.733,1.043-5.553-3.609-8.746-8.33s-4.863-4.721-6.249-3.746-1.944,3.746,1.665,10.827a92.708,92.708,0,0,1,7.914,23.045C159.674,276.051,167.73,283.406,177.588,283.822Z"
            transform="translate(733.305 -213.33)"
            fill={color || colors.blue}
          />
          <g
            id="Group_9"
            data-name="Group 9"
            transform="translate(980.848 1.623)"
          >
            <path
              id="Path_220"
              data-name="Path 220"
              d="M189.225,256.552s42.088-1.545,9.276-33.969a13.229,13.229,0,0,0-18.552,0c-32.811,32.424,9.276,33.969,9.276,33.969"
              transform="translate(-155.141 -189.302)"
              fill={color || colors.blue}
            />
            <g id="Group_8" data-name="Group 8">
              <path
                id="Path_221"
                data-name="Path 221"
                d="M176.14,213.742c2.851.5,5.776,3.358,7.583,9.561,1.528,5.223.639,8.883-.678,11.266a6.134,6.134,0,0,1-8.136,2.68c-2.206-1.146-4.664-3.563-6.2-8.58C166.767,222.362,168.375,212.374,176.14,213.742Z"
                transform="translate(-152.684 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_222"
                data-name="Path 222"
                d="M167.212,218.688a5.073,5.073,0,0,1,5.867-2.018c2.737.929,6.357,3.33,8.17,9.538,1.522,5.217.633,8.877-.679,11.26a6.143,6.143,0,0,1-8.142,2.68c-2.206-1.146-4.664-3.563-6.2-8.58C164.287,225.262,165.684,221.094,167.212,218.688Z"
                transform="translate(-165.279 -200.493)"
                fill={color || colors.blue}
              />
              <path
                id="Path_223"
                data-name="Path 223"
                d="M184.416,214.192a4.619,4.619,0,0,0-2.566-.57c-2.417.125-6.91,1.482-9.191,9.3-2.925,10.029,1.009,13.609,4.687,14.476,4.954,1.169,9.122-2.429,10.542-9.755C189.308,220.333,187.905,216.211,184.416,214.192Z"
                transform="translate(-135.372 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_224"
                data-name="Path 224"
                d="M186.555,216.559s-7.56-2.047-11.095,8.814c-3.216,9.886.61,13.216,4.265,14.539,4.783,1.733,9.185-2.138,10.821-9.379C192.314,222.7,190.66,218.486,186.555,216.559Z"
                transform="translate(-123.087 -200.491)"
                fill={color || colors.blue}
              />
            </g>
          </g>
          <path
            id="Path_225"
            data-name="Path 225"
            d="M247.658,219.352h0a17.291,17.291,0,0,0-24.459,0l-5.713,5.713-5.718-5.713a17.284,17.284,0,0,0-24.453,0h0a17.284,17.284,0,0,0,0,24.453l30.08,30.08.091-.091.091.091,30.081-30.08A17.3,17.3,0,0,0,247.658,219.352Z"
            transform="translate(895.344 -208.839)"
            fill={color || colors.blue}
          />
          <path
            id="Path_226"
            data-name="Path 226"
            d="M229.1,283.822s11.665.559,27.076-12.908,16.124-15.24,14.3-18.187c-1.807-2.919-6.249-1.67-11.249,1.665s-7.081,7.355-9.858,6.665-3.962-10.844.975-23.672c4.994-12.982,5.416-17.218,2.36-18.4-2.794-1.078-5.205.7-8.888,11.175-3.666,10.445-5.069,13.193-6.249,12.845-1.465-.433-1.249-10.137-1.106-16.386s-.279-13.29-4.316-13.29c-3.193,0-4.852,1.631-4.436,12.321s.559,16.8-.832,16.8c-1.425,0-4.23-9.168-5.758-15.137-.656-2.549-3-10.086-7.041-8.478-3.86,1.539-.77,11.386.063,14.886.165.69.342,1.374.519,2.064.7,2.634,2.218,10.747.7,11.659-1.733,1.043-5.553-3.609-8.746-8.33s-4.858-4.721-6.249-3.746-1.944,3.746,1.665,10.827a92.709,92.709,0,0,1,7.913,23.045C211.191,276.051,219.241,283.406,229.1,283.822Z"
            transform="translate(975.489 -213.33)"
            fill={color || colors.blue}
          />
          <g
            id="Group_11"
            data-name="Group 11"
            transform="translate(1274.543 1.623)"
          >
            <path
              id="Path_227"
              data-name="Path 227"
              d="M240.738,256.552s42.087-1.545,9.276-33.969a13.229,13.229,0,0,0-18.552,0c-32.811,32.424,9.276,33.969,9.276,33.969"
              transform="translate(-206.654 -189.302)"
              fill={color || colors.blue}
            />
            <g id="Group_10" data-name="Group 10">
              <path
                id="Path_228"
                data-name="Path 228"
                d="M227.659,213.742c2.845.5,5.77,3.358,7.583,9.561,1.522,5.223.633,8.883-.684,11.266a6.134,6.134,0,0,1-8.136,2.68c-2.207-1.146-4.664-3.563-6.2-8.58C218.28,222.362,219.893,212.374,227.659,213.742Z"
                transform="translate(-204.197 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_229"
                data-name="Path 229"
                d="M218.725,218.688a5.076,5.076,0,0,1,5.867-2.018c2.737.929,6.357,3.33,8.17,9.538,1.522,5.217.633,8.877-.678,11.26a6.138,6.138,0,0,1-8.136,2.68c-2.212-1.146-4.664-3.563-6.209-8.58C215.8,225.262,217.2,221.094,218.725,218.688Z"
                transform="translate(-216.792 -200.493)"
                fill={color || colors.blue}
              />
              <path
                id="Path_230"
                data-name="Path 230"
                d="M235.929,214.192a4.608,4.608,0,0,0-2.566-.57c-2.417.125-6.91,1.482-9.191,9.3-2.925,10.029,1.009,13.609,4.687,14.476,4.954,1.169,9.122-2.429,10.542-9.755C240.82,220.333,239.418,216.211,235.929,214.192Z"
                transform="translate(-186.885 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_231"
                data-name="Path 231"
                d="M238.066,216.559s-7.554-2.047-11.089,8.814c-3.221,9.886.61,13.216,4.259,14.539,4.784,1.733,9.185-2.138,10.821-9.379C243.824,222.7,242.171,218.486,238.066,216.559Z"
                transform="translate(-174.597 -200.491)"
                fill={color || colors.blue}
              />
            </g>
          </g>
          <path
            id="Path_232"
            data-name="Path 232"
            d="M299.171,219.352h0a17.291,17.291,0,0,0-24.459,0L269,225.065l-5.718-5.713a17.284,17.284,0,0,0-24.453,0h0a17.284,17.284,0,0,0,0,24.453l30.08,30.08.091-.091.091.091,30.081-30.08A17.3,17.3,0,0,0,299.171,219.352Z"
            transform="translate(1137.526 -208.839)"
            fill={color || colors.blue}
          />
          <path
            id="Path_233"
            data-name="Path 233"
            d="M280.612,283.822s11.665.559,27.076-12.908,16.123-15.24,14.3-18.187c-1.807-2.919-6.249-1.67-11.249,1.665s-7.081,7.355-9.858,6.665-3.962-10.844.975-23.672c5-12.982,5.416-17.218,2.36-18.4-2.788-1.078-5.205.7-8.889,11.175-3.66,10.445-5.063,13.193-6.243,12.845-1.465-.433-1.254-10.137-1.112-16.386s-.279-13.29-4.316-13.29c-3.193,0-4.852,1.631-4.43,12.321s.553,16.8-.838,16.8c-1.425,0-4.23-9.168-5.758-15.137-.656-2.549-2.993-10.086-7.041-8.478-3.86,1.539-.77,11.386.068,14.886.16.69.336,1.374.513,2.064.7,2.634,2.224,10.747.7,11.659-1.733,1.043-5.553-3.609-8.746-8.33s-4.858-4.721-6.249-3.746-1.944,3.746,1.665,10.827a92.482,92.482,0,0,1,7.914,23.045C262.7,276.051,270.754,283.406,280.612,283.822Z"
            transform="translate(1217.671 -213.33)"
            fill={color || colors.blue}
          />
          <g
            id="Group_13"
            data-name="Group 13"
            transform="translate(1568.239 1.623)"
          >
            <path
              id="Path_234"
              data-name="Path 234"
              d="M292.251,256.552s42.088-1.545,9.276-33.969a13.229,13.229,0,0,0-18.552,0c-32.811,32.424,9.276,33.969,9.276,33.969"
              transform="translate(-258.168 -189.302)"
              fill={color || colors.blue}
            />
            <g id="Group_12" data-name="Group 12">
              <path
                id="Path_235"
                data-name="Path 235"
                d="M279.172,213.742c2.845.5,5.77,3.358,7.583,9.561,1.522,5.223.633,8.883-.678,11.266a6.138,6.138,0,0,1-8.136,2.68c-2.212-1.146-4.67-3.563-6.209-8.58C269.793,222.362,271.406,212.374,279.172,213.742Z"
                transform="translate(-255.71 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_236"
                data-name="Path 236"
                d="M270.244,218.688a5.065,5.065,0,0,1,5.861-2.018c2.737.929,6.357,3.33,8.17,9.538,1.522,5.217.633,8.877-.678,11.26a6.136,6.136,0,0,1-8.136,2.68c-2.212-1.146-4.664-3.563-6.209-8.58C267.313,225.262,268.71,221.094,270.244,218.688Z"
                transform="translate(-268.305 -200.493)"
                fill={color || colors.blue}
              />
              <path
                id="Path_237"
                data-name="Path 237"
                d="M287.447,214.192a4.618,4.618,0,0,0-2.565-.57c-2.423.125-6.916,1.482-9.2,9.3-2.925,10.029,1.009,13.609,4.692,14.476,4.949,1.169,9.117-2.429,10.536-9.755C292.334,220.333,290.931,216.211,287.447,214.192Z"
                transform="translate(-238.398 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_238"
                data-name="Path 238"
                d="M289.584,216.559s-7.56-2.047-11.095,8.814c-3.221,9.886.61,13.216,4.259,14.539,4.783,1.733,9.191-2.138,10.821-9.379C295.343,222.7,293.684,218.486,289.584,216.559Z"
                transform="translate(-226.111 -200.491)"
                fill={color || colors.blue}
              />
            </g>
          </g>
          <path
            id="Path_239"
            data-name="Path 239"
            d="M350.684,219.352h0a17.291,17.291,0,0,0-24.459,0l-5.713,5.713-5.719-5.713a17.284,17.284,0,0,0-24.453,0h0a17.284,17.284,0,0,0,0,24.453l30.08,30.08.091-.091.091.091,30.08-30.08A17.3,17.3,0,0,0,350.684,219.352Z"
            transform="translate(1379.709 -208.839)"
            fill={color || colors.blue}
          />
          <path
            id="Path_240"
            data-name="Path 240"
            d="M332.125,283.822s11.665.559,27.076-12.908,16.129-15.24,14.3-18.187c-1.8-2.919-6.249-1.67-11.243,1.665s-7.081,7.355-9.858,6.665-3.968-10.844.969-23.672c5-12.982,5.416-17.218,2.36-18.4-2.788-1.078-5.206.7-8.889,11.175-3.66,10.445-5.063,13.193-6.243,12.845-1.465-.433-1.254-10.137-1.112-16.386s-.279-13.29-4.316-13.29c-3.193,0-4.846,1.631-4.43,12.321s.553,16.8-.832,16.8c-1.431,0-4.236-9.168-5.764-15.137-.656-2.549-2.993-10.086-7.041-8.478-3.86,1.539-.77,11.386.068,14.886.165.69.336,1.374.519,2.064.69,2.634,2.218,10.747.69,11.659-1.733,1.043-5.553-3.609-8.746-8.33s-4.858-4.721-6.249-3.746-1.944,3.746,1.665,10.827a92.735,92.735,0,0,1,7.919,23.045C314.217,276.051,322.267,283.406,332.125,283.822Z"
            transform="translate(1459.854 -213.33)"
            fill={color || colors.blue}
          />
          <g
            id="Group_15"
            data-name="Group 15"
            transform="translate(1861.934 1.623)"
          >
            <path
              id="Path_241"
              data-name="Path 241"
              d="M343.764,256.552s42.088-1.545,9.276-33.969a13.229,13.229,0,0,0-18.552,0c-32.812,32.424,9.276,33.969,9.276,33.969"
              transform="translate(-309.681 -189.302)"
              fill={color || colors.blue}
            />
            <g id="Group_14" data-name="Group 14">
              <path
                id="Path_242"
                data-name="Path 242"
                d="M330.685,213.742c2.845.5,5.77,3.358,7.583,9.561,1.522,5.223.633,8.883-.678,11.266a6.138,6.138,0,0,1-8.136,2.68c-2.212-1.146-4.664-3.563-6.209-8.58C321.306,222.362,322.919,212.374,330.685,213.742Z"
                transform="translate(-307.223 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_243"
                data-name="Path 243"
                d="M321.757,218.688a5.065,5.065,0,0,1,5.861-2.018c2.742.929,6.357,3.33,8.17,9.538,1.522,5.217.639,8.877-.678,11.26a6.136,6.136,0,0,1-8.136,2.68c-2.206-1.146-4.664-3.563-6.209-8.58C318.826,225.262,320.223,221.094,321.757,218.688Z"
                transform="translate(-319.818 -200.493)"
                fill={color || colors.blue}
              />
              <path
                id="Path_244"
                data-name="Path 244"
                d="M338.96,214.192a4.618,4.618,0,0,0-2.565-.57c-2.423.125-6.916,1.482-9.2,9.3-2.925,10.029,1.009,13.609,4.692,14.476,4.949,1.169,9.117-2.429,10.536-9.755C343.846,220.333,342.444,216.211,338.96,214.192Z"
                transform="translate(-289.911 -213.615)"
                fill={color || colors.blue}
              />
              <path
                id="Path_245"
                data-name="Path 245"
                d="M341.1,216.559s-7.56-2.047-11.095,8.814c-3.221,9.886.61,13.216,4.259,14.539,4.783,1.733,9.191-2.138,10.821-9.379C346.856,222.7,345.2,218.486,341.1,216.559Z"
                transform="translate(-277.623 -200.491)"
                fill={color || colors.blue}
              />
            </g>
          </g>
          <path
            id="Path_246"
            data-name="Path 246"
            d="M402.193,219.352h0a17.284,17.284,0,0,0-24.453,0l-5.719,5.713-5.713-5.713a17.292,17.292,0,0,0-24.459,0h0a17.3,17.3,0,0,0,0,24.453l30.081,30.08.091-.091.091.091,30.08-30.08A17.284,17.284,0,0,0,402.193,219.352Z"
            transform="translate(1621.894 -208.839)"
            fill={color || colors.blue}
          />
        </g>
      </svg>
    )}
  </>
);

export default SvgSelector;
