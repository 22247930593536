import { Link } from 'gatsby';
import React from 'react';

const LinkObject = (link) => (
  <>
    {link.newTab ? (
      <a href={link.url} target="_blank" rel="noopener noreferrer">
        <span
          dangerouslySetInnerHTML={{
            __html: link.copy,
          }}
        />
      </a>
    ) : (
      <Link to={link.url}>
        <span
          dangerouslySetInnerHTML={{
            __html: link.copy,
          }}
        />
      </Link>
    )}
  </>
);

export default LinkObject;
