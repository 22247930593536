import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import slugify from '../../js/slugify';
import Wrapper from '../../styles/utilities/Wrapper';
import {
  Input,
  Submit,
  Textarea,
  Fieldset,
} from '../../styles/reusableObjects/Inputs';
import Form from '../../styles/reusableObjects/Form';
import HeadingContent, {
  HeadingContainer,
} from '../reusableObjects/HeadingContent';

import { misc } from '../../styles/utilities/settings';

class ContactForm extends Component {
  state = {
    serial: null,
    honeypot: false,
  };

  collectData(id) {
    const form = global.document.getElementById(id);
    const inputs = form.querySelectorAll('input');
    const textareas = form.querySelectorAll('textarea');

    let serial = '';

    inputs.forEach((input) => {
      serial += `${input.name}=${input.value}&`;
    });

    textareas.forEach((input, index) => {
      serial += `${input.name}=${input.value}${
        textareas.length === index + 1 ? '' : '&'
      }`;
    });

    this.setState({
      serial,
    });
  }

  postForm() {
    const {
      content: { action, errorPage, successPage },
    } = this.props;
    const { serial } = this.state;

    axios
      .post(action, serial, { headers: { Accept: 'application/json' } })
      .then((response) => {
        console.log(response);
        global.window.location.href = successPage;
      })
      .catch((error) => {
        console.log(error);
        global.window.location.href = errorPage;
      });
  }

  render() {
    const {
      content,
      content: {
        sectionHeading,
        fields: { fieldSelect },
      },
    } = this.props;
    const { honeypot } = this.state;
    return (
      <SContactForm>
        <Wrapper narrow>
          <HeadingContent content={sectionHeading} />
          <Form
            id={content._key}
            onSubmit={(e) => {
              e.preventDefault();
              this.postForm();
            }}
          >
            <Fieldset
              onChange={() => {
                this.collectData(content._key);
              }}
              disabled={honeypot}
            >
              <Input
                type="text"
                name="hpFirst"
                onChange={() => {
                  this.setState(() => ({
                    honeypot: true,
                  }));
                }}
              />
              <div className="inner">
                {fieldSelect.map(
                  ({
                    _key,
                    _type,
                    type,
                    label,
                    extraClasses,
                    placeholder,
                    required,
                  }) => (
                    <React.Fragment key={_key}>
                      {_type === 'textarea' ? (
                        <label
                          htmlFor={slugify(label)}
                          className={extraClasses}
                        >
                          <span>{label}</span>
                          <Textarea
                            required={required}
                            placeholder={placeholder}
                            id={slugify(label)}
                            name={slugify(label)}
                          />
                        </label>
                      ) : (
                        <label
                          htmlFor={slugify(label)}
                          className={extraClasses}
                        >
                          <span>{label}</span>
                          <Input
                            required={required}
                            type={type}
                            placeholder={placeholder}
                            id={slugify(label)}
                            name={slugify(label)}
                          />
                        </label>
                      )}
                    </React.Fragment>
                  )
                )}
              </div>
            </Fieldset>
            <Submit
              disabled={honeypot}
              type="submit"
              id="submit"
              name="submit"
              value="Submit"
            />
          </Form>
        </Wrapper>
      </SContactForm>
    );
  }
}

export default ContactForm;

const SContactForm = styled.div`
  ${HeadingContainer} {
    text-align: center;
    margin-bottom: ${misc.sectionMargin / 2}px;
  }
`;
