import React from 'react';
import ImageLoader from '../helpers/ImageLoader';
import { breakpoints } from '../../styles/utilities/settings';
import Wrapper from '../../styles/utilities/Wrapper';

export default function LargeImage({ content }) {
  return (
    <>
      {content.contained ? (
        <Wrapper>
          <ImageLoader src={content.image} width={breakpoints.pageWidth} />
        </Wrapper>
      ) : (
        <ImageLoader src={content.image} width={breakpoints.pageWidth} />
      )}
    </>
  );
}
