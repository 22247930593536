import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import PageLink from '../helpers/PageLink';
import { richTextContent, button } from '../../styles/utilities/elements';
import { breakpoints } from '../../styles/utilities/settings';

const HeadingContent = ({
  hero,
  content: { heading, link, copy, copyText, author, date },
}) => (
  <HeadingContainer>
    {hero ? <h1>{heading}</h1> : <h2>{heading}</h2>}
    {author && <p>{`by ${author}`}</p>}
    {date && <p>{`published on ${date}`}</p>}
    {copy && <BlockContent blocks={copy} />}
    {copyText && <p>{copyText}</p>}
    {link && link.copy && <PageLink content={link} />}
  </HeadingContainer>
);

export default HeadingContent;

export const HeadingContainer = styled.div`
  position: relative;
  z-index: 1;
  ${richTextContent};

  > a {
    margin-top: 20px;
    ${button};
  }

  h1 {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      font-size: 50px;
    }
  }
`;
