import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import Wrapper from '../../styles/utilities/Wrapper';
import { breakpoints } from '../../styles/utilities/settings';

export default function RichText({ content }) {
  return (
    <ScRichText>
      <Wrapper narrow>
        <BlockContent blocks={content.copy} />
      </Wrapper>
    </ScRichText>
  );
}

export const ScRichText = styled.section`
  margin: 70px 0;

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    margin: 100px 0;
  }
`;
