import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { button } from '../../styles/utilities/elements';
import { breakpoints, colors } from '../../styles/utilities/settings';
import Wrapper from '../../styles/utilities/Wrapper';
import BackgroundImage, { SBackgroundImage } from '../helpers/BackgroundImage';
import LinkObject from '../reusableObjects/LinkObject';

const CampaignBlock = ({
  title,
  _rawMainImage,
  _rawSlug,
  description,
  _rawDonationlink,
}) => (
  <ScCampaignBlock>
    <BackgroundImage src={_rawMainImage} />
    <div className="copy">
      <div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <div>
        <LinkObject copy="See the Campaign" url={`/${_rawSlug.current}`} />
        {_rawDonationlink && (
          <LinkObject
            copy={_rawDonationlink.copy}
            url={_rawDonationlink.url}
            newTab={_rawDonationlink.newTab}
          />
        )}
      </div>
    </div>
  </ScCampaignBlock>
);

const CampaignsList = () => (
  <StaticQuery
    query={CAMPAIGNS_QUERY}
    render={({ allSanityCampaign: { edges } }) => (
      <Wrapper wide>
        <ScCampaignsList>
          {edges.map(({ node }) => (
            <CampaignBlock key={node._key} {...node} />
          ))}
        </ScCampaignsList>
      </Wrapper>
    )}
  />
);

export default CampaignsList;

const CAMPAIGNS_QUERY = graphql`
  {
    allSanityCampaign(sort: { fields: _createdAt, order: DESC }) {
      edges {
        node {
          title
          _key
          _rawMainImage
          _rawSlug
          description
          _rawDonationlink
        }
      }
    }
  }
`;

export const ScCampaignBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.blue};

  .copy {
    padding: 30px;
    height: calc(100% - 320px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: ${breakpoints.ipadMid + 1}px) {
      padding: 50px;
    }

    h3 {
      color: ${colors.white};
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 108px;
    }

    a {
      ${button};
      text-align: center;
      background-color: ${colors.black};
      margin-top: 20px;
      font-size: 13px;

      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        display: block;
        text-align: center;
        margin-top: 30px;
      }

      &:hover {
        background-color: ${colors.white};
        color: ${colors.black};
      }

      + a {
        background-color: ${colors.yellow};
        color: ${colors.black};

        @media screen and (min-width: ${breakpoints.ipadPort + 1}px) {
          margin-left: 15px;
        }

        @media screen and (max-width: ${breakpoints.ipadPort}px) {
          margin-top: 15px;
        }
      }
    }
  }

  ${SBackgroundImage} {
    min-height: 320px;
  }
`;

export const ScCampaignsList = styled.div`
  @media screen and (min-width: ${breakpoints.ipadPort + 1}px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  ${ScCampaignBlock} {
    @media screen and (max-width: ${breakpoints.ipadPort}px) {
      width: 100%;
      max-width: 400px;
      margin: 0 auto 35px;
    }

    @media screen and (min-width: ${breakpoints.ipadPort + 1}px) {
      width: calc(50% - 17.5px);
      margin: 0 0 35px;
    }
  }
`;
