import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import imageUrlFor, { buildImageObj } from '../../js/imageUrlFor';
import { colors, misc, breakpoints } from '../../styles/utilities/settings';
import Wrapper from '../../styles/utilities/Wrapper';

const ProgramsList = () => (
  <StaticQuery
    query={PROGRAMSLIST_QUERY}
    render={({ programs: { edges } }) => (
      <Wrapper>
        <SProgramsList>
          {edges.map(({ node: { id, title, icon, desc, slug } }) => (
            <ProgramItem key={id}>
              <div className="icon">
                <img
                  src={imageUrlFor(buildImageObj(icon))}
                  alt={`${title} icon`}
                  title={`${title} icon`}
                />
              </div>
              <div className="inner">
                <div className="copy">
                  <h3>{title}</h3>
                  <p>{desc}</p>
                  <Link to={`/programs/${slug.current}`}>Learn More</Link>
                </div>
              </div>
            </ProgramItem>
          ))}
        </SProgramsList>
      </Wrapper>
    )}
  />
);

export default ProgramsList;

const PROGRAMSLIST_QUERY = graphql`
  {
    programs: allSanityProgram(sort: { order: ASC, fields: title }) {
      edges {
        node {
          id
          title
          icon: _rawIcon
          desc: description
          slug: _rawSlug
        }
      }
    }
  }
`;

const ProgramItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  .icon {
    background-color: ${colors.lightYellow};
    display: flex;
    margin: 0 auto -80px;
    height: 128px;
    width: 128px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: relative;
    z-index: 2;

    img {
      display: block;
      height: 70px;
      width: 70px;
      margin: 0;
    }
  }

  .inner {
    background-color: ${colors.yellow};
    padding: 100px 50px 50px;
  }

  p {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.7;
  }

  a {
    color: ${colors.black};
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    position: relative;
    padding-bottom: 5px;

    &:after {
      content: '';
      display: block;
      margin: 0 auto;
      width: 80%;
      height: 3px;
      background-color: ${colors.lightYellow};
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      transition-duration: ${misc.animSpeed};
    }

    &:hover {
      color: ${colors.lightYellow};
      &:after {
        width: 100%;
      }
    }
  }
`;

const SProgramsList = styled.div`
  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    display: flex;
    flex-wrap: wrap;
  }

  ${ProgramItem} {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      width: 50%;
      padding: 0 15px 30px;
    }

    + ${ProgramItem} {
      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        padding-top: 30px;
      }
    }
  }
`;
