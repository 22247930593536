import styled from 'styled-components';
import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Wrapper from '../../styles/utilities/Wrapper';
import { colors, breakpoints } from '../../styles/utilities/settings';

const HeadingSection = ({
  narrowCopy,
  content: {
    sectionHeading: { copy, heading },
  },
}) => (
  <SHeadingSection>
    <Wrapper narrow={!narrowCopy} narrower={narrowCopy}>
      <h2>{heading}</h2>
      {copy && <BlockContent blocks={copy} />}
    </Wrapper>
  </SHeadingSection>
);

export default HeadingSection;

export const SHeadingSection = styled.div`
  text-align: center;

  @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
    padding: 0 15px;
  }

  h2 {
    color: ${colors.blue};
    margin-bottom: 20px;
    line-height: 1.5;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      font-size: 30px;
    }
  }
`;
